<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="'600px'"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.confirmTransferVoucher')"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl
          fluid
          style="padding: 0;">
          <v-form
            ref="form"
            lazy-validation>
            <v-layout
              layout
              wrap>
              <v-flex
                xs12
                sm12
                md12>
                <div>
                  {{ $t("evoucher.voucherSerial") }}:
                  <span style="font-weight: bold;">{{
                    voucherInfo && voucherInfo.voucherSerial
                  }}</span>
                </div>
                <div>
                  {{ $t("evoucher.issuance.pricePercent") }}:
                  <span style="font-weight: bold;">{{
                    voucherInfo && voucherInfo.value
                  }}</span>
                </div>
                <div>
                  {{ $t("entity.supplier") }}:
                  <span style="font-weight: bold;">{{
                    voucherInfo && voucherInfo.supplier
                  }}</span>
                </div>
                <div>
                  {{ $t("createVoucher.endDateOfUse") }}:
                  <span style="font-weight: bold;">{{
                    voucherInfo && voucherInfo.expiredDate
                  }}</span>
                </div>
              </v-flex>
              <v-divider style="margin: 0" />
              <v-flex
                xs12
                sm12
                md12>
                <v-text-field
                  v-model="emailCustomer"
                  :rules="[ruleRequiredValue, ruleValidEmail]"
                  :label="$t('customer.emailCustomer')"
                  :name="$t('customer.emailCustomer')"
                  class="required"
                  type="email"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="isShowModal = false"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm($event)"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
import CreateVoucherType from 'enum/createVoucherType'
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      isShowModal: false,
      activeCode: null,
      isLoading: false,
      emailCustomer: null,
      voucherInfo: null
    }
  },
  methods: {
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidEmail (value) {
      return validationRules.ruleValidEmailFormat(value)
    },
    /**
     * Confirm
     */
    onConfirm: function (event) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        if (!functionUtils.validateEmail(this.emailCustomer)) {
          this.ON_SHOW_TOAST({
            message: this.$t('validation.invalidEmail'),
            styletype: ToastType.ERROR
          })
        } else {
          this.isLoading = true
          let data = {
            type: CreateVoucherType.SINGLE,
            voucherIds: [],
            voucherId: this.voucherInfo ? this.voucherInfo.id : null,
            email: this.emailCustomer.trim().toLowerCase(),
            customerUrl: stringUtils.CUSTOMER_URL
          }
          this.TRANSACTION_FROM_CUSTOMER_TO_CUSTOMER(data)
            .then(
              function () {
                this.isLoading = false
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.onCloseModal()
              }.bind(this)
            )
            .catch(
              function (error) {
                this.isLoading = false
                if (!functionUtils.isEmptyString(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    message: this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styletype: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        }
      }
    },
    /**
     * Show modal
     */
    onShowModal: function (itemVoucher) {
      this.isShowModal = true
      this.isLoading = false
      this.voucherInfo = itemVoucher
    },
    /**
     * Confirm failed
     */
    onConfirmFailed: function () {
      this.isLoading = false
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.isLoading = false
      this.emailCustomer = null
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'TRANSACTION_FROM_CUSTOMER_TO_CUSTOMER'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
