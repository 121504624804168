<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="forConasi || discountType == VoucherValue.PRICE ? '600px' : '800px'"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.confirmUsedVoucher')"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl
          fluid
          style="padding: 0;">
          <v-form
            ref="form"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <v-flex
                v-if="!forConasi"
                xs6
                sm6
                md6>{{ $t('evoucher.customer') }}: <span style="font-weight: bold;">{{ customerName }}</span></v-flex>
              <v-flex
                v-if="!forConasi"
                xs6
                sm6
                md6>{{ $t('evoucher.voucherSerial') }}: <span style="font-weight: bold;">{{ voucherSerial }}</span></v-flex>
              <v-flex
                v-if="!forConasi"
                xs6
                sm6
                md6>{{ $t('createVoucher.receivedDate') }}: <span style="font-weight: bold;">{{ deliveredDate }}</span></v-flex>
              <v-flex
                v-if="!forConasi"
                xs6
                sm6
                md6>{{ $t('createVoucher.endDateOfUse') }}: <span style="font-weight: bold;">{{ expiredDate }}</span></v-flex>
              <v-flex
                v-if="forConasi || discountType == VoucherValue.PRICE"
                xs12
                sm12
                md12>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="activeCode"
                  :label="$t('evoucher.activeCode')"
                  :name="$t('evoucher.activeCode')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                v-else
                xs6
                sm6
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="activeCode"
                  :label="$t('evoucher.activeCode')"
                  :name="$t('evoucher.activeCode')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <!-- Discount percent -->
              <v-flex
                v-if="!forConasi && discountType == VoucherValue.PERCENT"
                xs6
                sm6
                md6>
                <v-text-field
                  v-model="discountValue"
                  :label="$t('evoucher.discountPrice') + ' (%)'"
                  :name="$t('evoucher.discountPrice') + ' (%)'"
                  disabled
                  type="text"
                />
              </v-flex>
            </v-layout>
            <v-layout
              v-if="!forConasi && discountType == VoucherValue.PERCENT"
              layout
              wrap>
              <v-flex
                xs12
                sm12
                md12><span><span style="color: red;">* </span>{{ $t('evoucher.issuance.usedValidWithTypePercent') }}</span></v-flex>
              <!-- Min value -->
              <v-flex
                v-if="!forConasi"
                xs6
                sm6
                md6>
                <v-text-field
                  v-model="minValueFormat"
                  :label="$t('evoucher.issuance.fromValue')"
                  :name="$t('evoucher.issuance.fromValue')"
                  disabled
                  type="text"
                />
              </v-flex>
              <!-- Max value -->
              <v-flex
                v-if="!forConasi"
                xs6
                sm6
                md6>
                <v-text-field
                  v-model="maxValueFormat"
                  :label="$t('evoucher.issuance.toValue')"
                  :name="$t('evoucher.issuance.toValue')"
                  disabled
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs6
                sm6
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="usedPriceFormat"
                  :label="$t('evoucher.usedPrice')"
                  :name="$t('evoucher.usedPrice')"
                  type="text"
                  class="required"
                  @input="formatValue"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs6
                sm6
                md6>
                <v-text-field
                  v-model="discountPriceFormat"
                  :label="$t('evoucher.discountValuePrice')"
                  :name="$t('evoucher.discountValuePrice')"
                  disabled
                  type="text"
                />
              </v-flex>
            </v-layout>
            <v-layout
              layout
              wrap>
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  v-model="usedInfo.orderCode"
                  :rules="[ruleRequiredValue]"
                  :label="$t('evoucher.referenceCode')"
                  :name="$t('evoucher.referenceCode')"
                  class="required"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  v-model="usedInfo.priceFormat"
                  :label="$t('evoucher.issuance.price')"
                  :name="$t('evoucher.issuance.price')"
                  type="text"
                  @input="formatValueInput"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="usedInfo.customerName"
                  :label="$t('evoucher.customerUsed')"
                  :name="$t('evoucher.customerUsed')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="usedInfo.customerEmail"
                  :label="$t('customer.emailCustomer')"
                  :name="$t('customer.emailCustomer')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="usedInfo.customerPhone"
                  :label="$t('customer.phone')"
                  :name="$t('customer.phone')"
                  type="text"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="isShowModal = false"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm($event)"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { FOR_CONASI_TARGET } from 'utils/constants'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import VoucherValue from 'enum/voucherValue'
export default {
  data () {
    return {
      isShowModal: false,
      activeCode: null,
      isLoading: false,
      VoucherValue: VoucherValue,
      discountType: null,
      // eslint-disable-next-line eqeqeq
      forConasi: FOR_CONASI_TARGET,
      discountValue: null,
      minValue: null,
      minValueFormat: null,
      maxValue: null,
      maxValueFormat: null,
      usedPriceFormat: null,
      usedPrice: null,
      discountPriceFormat: null,
      discountPrice: null,
      customerName: null,
      voucherSerial: null,
      deliveredDate: null,
      expiredDate: null,
      usedInfo: {
        orderCode: '',
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        price: null,
        priceFormat: ''
      }
    }
  },
  methods: {
    /**
     * Format value
     */
    formatValue: function (value) {
      value = functionUtils.formatInteger(value && value.toString())
      this.usedPriceFormat = functionUtils.convertFormatNumberToMoney(value)
      this.usedPrice = value
      if (this.usedPrice >= this.minValue && this.usedPrice <= this.maxValue) {
        this.discountPrice = Math.round(this.usedPrice * (this.discountValue / 100))
      } else if (this.usedPrice >= this.minValue) {
        this.discountPrice = Math.round(this.maxValue * (this.discountValue / 100))
      } else {
        this.discountPrice = 0
      }
      this.discountPriceFormat = functionUtils.convertFormatNumberToMoney(this.discountPrice)
    },
    /**
     * Format value input
     */
    formatValueInput: function (value) {
      value = functionUtils.formatInteger(value && value.toString())
      this.usedInfo.priceFormat = functionUtils.convertFormatNumberToMoney(value)
      this.usedInfo.price = value
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Confirm
     */
    onConfirm: function (event) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        this.isLoading = true
        if (this.activeCode) {
          this.activeCode = this.activeCode.trim()
        }
        this.$emit('onConfirm', this.activeCode, this.usedPrice, this.discountPrice, this.usedInfo)
      }
    },
    /**
     * Show modal
     */
    onShowModal: function (itemVoucher) {
      this.isShowModal = true
      this.isLoading = false
      this.activeCode = null
      if (!this.forConasi) {
        this.discountType = itemVoucher.workpiece.type
        this.discountValue = itemVoucher.workpiece.value
        this.minValue = itemVoucher.workpiece.minValue
        this.maxValue = itemVoucher.workpiece.maxValue
        this.minValueFormat = functionUtils.convertFormatNumberToMoney(this.minValue)
        this.maxValueFormat = functionUtils.convertFormatNumberToMoney(this.maxValue)
        this.customerName = this.getCustomerDisplayName(itemVoucher)
        this.voucherSerial = itemVoucher.serial
        this.deliveredDate = itemVoucher.deliveredDate
        this.expiredDate = itemVoucher.expiredDate
      }
      this.usedInfo.priceFormat = functionUtils.convertFormatNumberToMoney(functionUtils.formatInteger(itemVoucher.value))
      this.usedInfo.price = functionUtils.formatInteger(itemVoucher.value)
      this.usedInfo.customerName = this.getCustomerDisplayName(itemVoucher)
    },
    getCustomerDisplayName: function (itemVoucher) {
      let customerName = itemVoucher.customerName
      if (!this.forConasi && !functionUtils.isEmptyString(itemVoucher.deliveryFullName)) {
        customerName = itemVoucher.deliveryFullName
      }
      return customerName
    },
    /**
     * Confirm failed
     */
    onConfirmFailed: function () {
      this.isLoading = false
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.discountType = null
      this.discountValue = null
      this.minValue = null
      this.maxValue = null
      this.minValueFormat = null
      this.maxValueFormat = null
      this.customerName = null
      this.voucherSerial = null
      this.deliveredDate = null
      this.expiredDate = null
      this.discountPrice = null
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
