<template>
  <v-dialog
    v-model="isShowModal"
    max-width="100%">
    <v-card>
      <material-card
        :title="$t('evoucher.historyTransaction')"
        tile
        color="green"
        full-width
      >
        <v-progress-linear
          v-show="progressBar"
          :indeterminate="progressBar"
          color="success"
          height="5"
          value="30"
        />
        <v-data-table
          :no-data-text="$t('common.noDataAvailable')"
          :headers="voucherHistoryHeaders"
          :items="voucherHistoryList"
          :loading="true"
          hide-actions
        >
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="text--darken-3 font-medium"
              v-text="$t(header.text)"
            />
          </template>
          <template
            slot="items"
            slot-scope="{ item }">
            <td
              v-for="(header, index) in voucherHistoryHeaders"
              :key="header.value + index"
            >
              <div class="mx-1">
                <!-- <div v-if="header.value == 'voucherSerial'">
                  <div
                    v-for="(voucherSerial, index) in item[header.value]"
                    :key="voucherSerial + index">
                    {{ voucherSerial }}
                  </div>
                  <div v-if="item[header.value].length >= 5">...</div>
                </div> -->
                <div v-if="header.value == 'customer'">
                  <span v-if="item.customerName">{{ item.customerName }}</span><br>
                  <span v-if="item.customerEmail">{{ item.customerEmail }}</span>
                </div>
                <div v-else-if="header.value == 'userAction'">
                  <span v-if="item.userName">{{ item.userName }}</span><br>
                  <span v-if="item.userEmail">{{ item.userEmail }}</span>
                </div>
                <div
                  v-else-if="header.value == 'action'"
                  style="white-space: nowrap;">
                  {{ $t(getActionName(item[header.value])) }}
                </div>
                <div v-else>{{ item[header.value] }}</div>
              </div>
            </td>
          </template>
        </v-data-table>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          class="mt-2"
          @onPageChange="onPageChange"
        />
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error darken-1"
          @click="isShowModal = false"
        >{{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// import VoucherHistoryType from 'enum/voucherHistoryType'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
export default {
  data: () => ({
    voucherHistoryHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherHistory.dateAction', value: 'dateCreated' },
      // { text: 'evoucher.voucherSerial', value: 'voucherSerial' },
      { text: 'voucherHistory.implementAgencies', value: 'fromEntity' },
      { text: 'voucherHistory.receivingAgencies', value: 'toEntity' },
      { text: 'voucherHistory.customer', value: 'customer' },
      { text: 'voucherHistory.peopleAction', value: 'userAction' },
      { text: 'voucherHistory.action', value: 'action' },
      { text: 'evoucher.issuance.description', value: 'description' }
    ],
    voucherHistoryList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    isShowModal: false,
    voucherId: null,
    progressBar: false
  }),
  computed: {
    ...mapGetters([
      'VOUCHER_HISTORY_DATA'
    ])
  },
  watch: {
    VOUCHER_HISTORY_DATA () {
      let res = this.VOUCHER_HISTORY_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle data
      let data = res.results
      this.voucherHistoryList = []
      for (let i = 0, size = data.length; i < size; i++) {
        // let voucherSerialCodeList = []
        let description = functionUtils.parseVoucherHistoryDescription(
          data[i].type,
          data[i].from_entity, data[i].to_entity,
          data[i].from_user, data[i].to_user,
          data[i].voucher_list.length,
          data[i].product_order, data[i].store_channel_data
        )

        // for (let j = 0, voucherSize = (data[i].voucher_list.length >= 5 ? 5 : data[i].voucher_list.length); j < voucherSize; j++) {
        //   voucherSerialCodeList.push(data[i].voucher_list[j].serial)
        // }
        let voucherHistoryObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: data[i].id,
          fromEntity: data[i].from_entity.name !== '' ? data[i].from_entity.name : ((data[i].from_user.last_name || data[i].from_user.first_name) ? functionUtils.concatFullname(data[i].from_user.last_name, data[i].from_user.first_name) : data[i].from_user.email),
          toEntity: data[i].to_entity.name,
          userName: functionUtils.concatFullname(data[i].user_created.last_name, data[i].user_created.first_name),
          userEmail: data[i].user_created.email,
          description: description,
          dateCreated: dateUtils.formatBeautyDate(data[i].date_created),
          // voucherSerial: voucherSerialCodeList,
          customerName: functionUtils.concatFullname(data[i].to_user.last_name, data[i].to_user.first_name),
          customerEmail: data[i].to_user.email,
          toUser: (data[i].to_user.last_name || data[i].to_user.first_name) ? functionUtils.concatFullname(data[i].to_user.last_name, data[i].to_user.first_name) : data[i].to_user.email,
          action: data[i].type
        }
        this.voucherHistoryList.push(voucherHistoryObj)
      }
      this.progressBar = false
    }
  },
  created () {
    // this.getVoucherHistoryList()
  },
  methods: {
    /**
     * Get action name
     */
    getActionName: function (type) {
      return functionUtils.getHistoryActionName(type)
    },
    /**
     * Show modal
     */
    onShowModal: function (voucherId) {
      this.voucherHistoryList = []
      this.voucherId = voucherId
      this.getVoucherHistoryList()
      this.isShowModal = true
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getVoucherHistoryList()
    },
    /**
     * Get voucher history list
     */
    getVoucherHistoryList: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          voucherId: this.voucherId
        }
      }
      this.progressBar = true
      this.GET_VOUCHER_HISTORY_LIST(filter).then(
        function () {
          this.progressBar = false
        }.bind(this)
      ).catch(
        function () {
          this.progressBar = false
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_VOUCHER_HISTORY_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/table.v-table tbody td {
  padding: 0 !important;
}
/deep/table.v-table thead th {
  padding-left: 0 !important;
}
</style>
